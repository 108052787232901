import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Layout, MainContainer, AsideContainer } from "src/components/layout"
import { LatestPostsAside } from "src/components/asides/latest_posts"
import hr from "src/images/hr.png"

const BlogContainer = styled.div `
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    /* flex-wrap: wrap; */
    flex-direction: row;
  }
`

const PostHeader = styled.div `
  /* margin-top: 2.5rem; */
`

const PostLabel = styled.div `
  color: #C22700;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
`

const PostTitle = styled.h1 `
  margin-top: 1rem;
  margin-bottom: 0;
  color: #000;
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 300;
`

const PostSubtitle = styled.h2 `
  margin-top: 1rem;
  margin-bottom: 0;
  color: #000;
  line-height: 1.4;
  font-weight: 400;
  /* font-style: italic; */
  font-family: "EB Garamond";
  /* font-size: 2.5rem; */
`

const PostDate = styled.div `
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  /* font-weight: 700; */
  color: #777;
`

const PostCover = styled.img `
  /* margin-top: 2.5rem; */
  margin-bottom: 2rem;
`

const PostBody = styled.div `
  margin: 0rem auto 0;
  font-size: 1.25rem;
  line-height: 1.6;
  font-family: "EB Garamond";

  & > p > a, & > p > em > a {
    color: #C22700;
    text-decoration: none;
    padding-bottom: 0.125rem;
    border-bottom: 1px solid #C22700;
  }

  & > hr {
    margin: 4rem 0;
    height: 6px;
	  background: url(${hr}) repeat-x 0 0;
    border: 0;
  }

  & > h3 {
    margin-top: 4rem;
    font-size: 1.5rem;
    line-height: 1.6;
  }

  & > blockquote {
    /* font-family: Lato; */
    font-weight: 400;
    line-height: 1.6;
    font-size: 1rem;
    padding: 0rem 0 0 1.5rem;  
    margin: 0 0 1.5rem 0;
    border-left: 4px solid #C22700;
    /* background: #FFF1F1; */
    box-sizing: border-box;

    @media (min-width: 1024px) {
      font-size: 1.25rem;
      padding: 0rem 3rem;  
    }

    & > p {
      /* margin-bottom: 0; */
    }

    & > p > cite {
      margin-top: 1.25rem;
      display: block;
      font-weight: 400;
      /* font-style: normal; */
    }
  }

  & > .sidebar {
    background: #f2f2f2;
    padding: 1.25rem;
    margin-bottom: 2rem;
    
    & > .title {
      font-family: Lato;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    & > .body {
      font-family: Lato;
      font-size: 1rem;

      & > a {
        color: #C22700;
        text-decoration: none;
      }
    }
  }
`

const BlogPostTemplate = (props) => {

  const post = props.data.markdownRemark;
  const site = props.data.site;

  return (
    <Layout>
      <Helmet title={`${post.frontmatter.title} | ${site.siteMetadata.title}`} />
      <BlogContainer>
        <MainContainer>
          <PostHeader>
            <PostLabel>{post.frontmatter.label ? post.frontmatter.label : "Blog"}</PostLabel>
            <PostTitle>{post.frontmatter.title}</PostTitle>
            <PostSubtitle>{post.frontmatter.subtitle}</PostSubtitle>
            <PostDate>{post.frontmatter.date}</PostDate>
          </PostHeader>
          {post.frontmatter.cover && <PostCover src={post.frontmatter.cover.childImageSharp.fixed.src} />}
          <PostBody dangerouslySetInnerHTML={{ __html: post.html }} />
        </MainContainer>
        <AsideContainer>
          <LatestPostsAside />
        </AsideContainer>
      </BlogContainer>
    </Layout>
  );
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        label
        date(formatString: "MMMM Do, YYYY")
        cover {
          childImageSharp {
            fixed(width: 1024) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
